@import "../variables/colors.scss";

// Third party library styles to override

.flatfile-sdk .flatfile-close:after {
  color: black !important;
}

.react-flow__handle.connectionindicator {
  width: 10px;
  height: 10px;
  background-color: $primary;
  border: 0px;
}
