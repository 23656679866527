$primary: #00c986;
$secondary: #2d37ff;
$warning: #ed6c02;
$info: #29b6f6;
$textPrimary: #000000;

// Status chip colors
$statusVerified: #00c986;
$statusUnverified: #c97f00;
$statusInviteSent: #005ec9;

// Newsletter state colors
$ready: #ffea02;

// Stats colors
$delivered: #c66be9;
$opened: #ffcc00;
$clicked: #ff9d00;
$replied: #0077ff;
$converted: #00c986;
$bounced: #00b0ff;
$unsubscribed: #ff5959;
$spamComplaint: #4e4d4d;

// Workflow builder colors
$messages: #ff9d00;
$delays: #0077ff;
$productivity: #00ddff;
$flow: #ffea02;

// Common colors
$star: #F4C867;

:export {
  primary: $primary;
  secondary: $secondary;
  warning: $warning;
  info: $info;
  textPrimary: $textPrimary;

  ready: $ready;

  statusVerified: $statusVerified;
  statusUnverified: $statusUnverified;
  statusInviteSent: $statusInviteSent;

  delivered: $delivered;
  opened: $opened;
  clicked: $clicked;
  replied: $replied;
  converted: $converted;
  bounced: $bounced;
  unsubscribed: $unsubscribed;
  spamComplaint: $spamComplaint;

  messages: $messages;
  delays: $delays;
  productivity: $productivity;
  flow: $flow;

  star: $star
}
